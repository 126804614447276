/*
 * @Description: 
 * @Author: 高泽峰 Design.Gao
 * @Date: 2024-09-24 13:50:17
 * @LastEditTime: 2024-11-24 20:00:19
 * @LastEditors:  
 */
import http from "../utils/http.js";
import Base from "./Base.js";

export default class System extends Base {
  constructor(props) {
    super(props);
  }

  //*********数据*************************************
  static getData(parm) {
    return http.post(`${Base.API}/system/dict/data/get`, parm);
  }
  static listData (parm) {
    return http.post(`${Base.API}/system/dict/data/list`, parm);
  }

  static addData(parm) {
    return http.post(`${Base.API}/system/dict/data/add`, parm);
  }
  static editData(parm) {
    return http.post(`${Base.API}/system/dict/data/edit`, parm);
  } 
  static changeDataStatus(parm) {
    return http.post(`${Base.API}/system/dict/data/edit_status`, parm);
  }

  static SelectListData(parm) {
    return http.post(`${Base.API}/system/dict/data/get_select_list`, parm);
  }

  static delData(parm) {
    return http.post(`${Base.API}/system/dict/data/del`, parm);
  }
 
 
 
  //*********分类*************************************
  static listType (parm) {
    return http.post(`${Base.API}/system/dict/type/list`, parm);
  }
  static listTypeAll (parm) {
    return http.post(`${Base.API}/system/dict/type/list_all`, parm);
  }
  
  static addType(parm) {
    return http.post(`${Base.API}/system/dict/type/add`, parm);
  }
  static editType(parm) {
    return http.post(`${Base.API}/system/dict/type/edit`, parm);
  } 
  static changeTypeStatus(parm) {
    return http.post(`${Base.API}/system/dict/type/edit_status`, parm);
  }
  static delType(parm) {
    return http.post(`${Base.API}/system/dict/type/del`, parm);
  }
  static getType(parm) {
    return http.post(`${Base.API}/system/dict/type/get`, parm);
  }
  //**软件升级*******************************************************************

    //*********数据*************************************
    static getUpgrade(parm) {
      return http.post(`${Base.API}/system/upgrade/get`, parm);
    }
    static listUpgrade (parm) {
      return http.post(`${Base.API}/system/upgrade/list`, parm);
    } 
    static addUpgrade(parm) {
      return http.post(`${Base.API}/system/upgrade/add`, parm);
    }
    static editUpgrade(parm) {
      return http.post(`${Base.API}/system/upgrade/edit`, parm);
    }  
    static delUpgrade(parm) {
      return http.post(`${Base.API}/system/upgrade/del`, parm);
    }
   
   
 


}
