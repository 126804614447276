/*
 * @Author: 界点 5223308+界点@user.noreply.gitee.com
 * @Date: 2024-09-23 20:30:58
 * @LastEditors:  
 * @LastEditTime: 2024-11-30 10:34:41
 * @FilePath: \界点\src\api\Login.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import http from "../utils/http";
import Base from "./Base";

export default class Login extends Base {
    constructor(props) {
        super(props);
    }

    static toLogin(parm) {
        // return new Promise((resovle, reject) => {//当接口没有的时候,模拟接口数据返回
        //     setTimeout(() => {
        //         let res = {
        //             code: 200,
        //             data: {
        //                 token: "gaozefeng123.。。。。。",
        //             },
        //         };
        //         resovle(res);
        //     }, 300);
        // });

        return http.post(`${Base.API}/auth/login`, parm);
    }


    //此处模拟返回用户信息表

    static userInfo() {
        return new Promise((resovle, reject) => {
            let res = {
                code: 200,
                data: {
                    userId: "123456",
                    userName: "mingkong",
                    nickName: "高泽峰",
                    role: "admin",
                },
            };
            resovle(res);
        });
    }




    static menuList() {
        return new Promise((resovle, reject) => { //模拟接口静态路由访问
            let res = [{
                    path: "/home",
                    name: "Home",
                    component: "",
                    meta: {
                        title: "统计首页",
                        icon: "DataLine",
                        role: ["admin"],
                        isShow: true,
                    },
                },
                {
                    path: "/consumer",
                    name: "Consumer",
                    component: "",
                    meta: {
                        title: "商户管理",
                        icon: "User",
                        role: ["admin"],
                        isShow: true,
                    },
                    children: [{
                            path: "consumerList",
                            name: "consumerList",
                            icon: "setting",
                            component: "@/views/consumer/merchant/index.vue",
                            meta: {
                                title: "商户列表",
                                icon: "User",
                                role: ["admin"],
                                isShow: true,
                            },
                        },
                        {
                            path: "staff",
                            name: "staff",
                            icon: "setting",
                            component: "@/views/consumer/staff/index.vue",
                            meta: {
                                title: "商户员工",
                                icon: "Tickets",
                                role: ["admin"],
                                isShow: true,
                            },
                        },


                    ]

                },
                {
                    path: "/user",
                    name: "User",
                    component: "",
                    meta: {
                        title: "会员管理",
                        icon: "User",
                        role: ["admin"],
                        isShow: true,
                    },
                    children: [{
                            path: "userList",
                            name: "userList",
                            icon: "setting",
                            component: "@/views/user/userList/index.vue",
                            meta: {
                                title: "会员列表",
                                icon: "User",
                                role: ["admin"],
                                isShow: true,
                            },
                        },
                        {
                            path: "userDetails",
                            name: "userDetails",
                            icon: "setting",
                            component: "@/views/user/userDetails/index.vue",
                            meta: {
                                title: "会员详情",
                                icon: "User",
                                role: ["admin"],
                                isShow: false,
                            },
                        },
                        {
                            path: "pay",
                            name: "pay",
                            icon: "setting",
                            component: "@/views/user/pay/index.vue",
                            meta: {
                                title: "支付列表",
                                icon: "Tickets",
                                role: ["admin"],
                                isShow: true,
                            },
                        },


                    ]

                },
                // {
                //     path: "/team",
                //     name: "Team",
                //     component: "@/views/team/index.vue",
                //     meta: {
                //         title: "团队管理",
                //         icon: "User",
                //         role: ["admin"],
                //         isShow: true,
                //     },
                //     children: [{
                //             path: "teamList",
                //             name: "teamList",
                //             icon: "setting",
                //             component: "@/views/team/index.vue",
                //             meta: {
                //                 title: "团队成员列表",
                //                 icon: "User",
                //                 role: ["admin"],
                //                 isShow: true,
                //             },
                //         },


                //     ]

                // },

                // {
                //     path: "/points",
                //     name: "Points",
                //     component: "@/views/points/index.vue",
                //     meta: {
                //         title: "积分管理",
                //         icon: "User",
                //         role: ["admin"],
                //         isShow: true,
                //     },
                //     children: [{
                //             path: "pointsList",
                //             name: "pointsList",
                //             icon: "setting",
                //             component: "@/views/points/index.vue",
                //             meta: {
                //                 title: "积分明细",
                //                 icon: "User",
                //                 role: ["admin"],
                //                 isShow: true,
                //             },
                //         },


                //     ]

                // },

                // {
                //     path: "/rights",
                //     name: "Rights",
                //     component: "@/views/rights/index.vue",
                //     meta: {
                //         title: "权益管理",
                //         icon: "User",
                //         role: ["admin"],
                //         isShow: true,
                //     },
                //     children: [{
                //             path: "rightsList",
                //             name: "rightsList",
                //             icon: "setting",
                //             component: "@/views/rights/index.vue",
                //             meta: {
                //                 title: "权益列表",
                //                 icon: "User",
                //                 role: ["admin"],
                //                 isShow: true,
                //             },
                //         },


                //     ]

                // }, 
                // {
                //     path: "/level",
                //     name: "Level",
                //     component: "@/views/level/index.vue",
                //     meta: {
                //         title: "级别管理",
                //         icon: "Tickets",
                //         role: ["admin"],
                //         isShow: true,
                //     },
                // },
                {
                    path: "/goods",
                    name: "Goods",
                    component: "",
                    icon: "Present",
                    meta: {
                        title: "商品管理",
                        icon: "Present",
                        role: ["admin"],
                        isShow: true,
                    },
                    children: [{
                            path: "product",
                            name: "Product",
                            icon: "Present",
                            component: "@/views/goods/product/index.vue",
                            meta: {
                                title: "商品列表",
                                icon: "Tickets",
                                role: ["admin"],
                                isShow: true,
                                keepAlive: true,
                            },

                        },
                        {
                            path: "product/update",
                            name: "update",
                            icon: "Present",
                            component: "@/views/goods/product/update.vue",
                            meta: {
                                title: "编辑商品",
                                icon: "Tickets",
                                role: ["admin"],
                                isShow: false,
                                keepAlive: true,
                            },
                        },
                        {
                            path: "product/category",
                            name: "category",
                            icon: "Operation",
                            component: "@/views/goods/category/index.vue",
                            meta: {
                                title: "商品分类管理",
                                icon: "Tickets",
                                role: ["admin"],
                                isShow: true,
                            },
                        },
                        {
                          path: "product/tag",
                          name: "tag",
                          icon: "Operation",
                          component: "@/views/goods/tag/index.vue",
                          meta: {
                              title: "商品标签管理",
                              icon: "Tickets",
                              role: ["admin"],
                              isShow: true,
                          },
                      },

                        


                    ]

                },
                {
                    path: "/order",
                    name: "Order",
                    component: "",
                    meta: {
                        title: "订单管理",
                        icon: "Tickets",
                        role: ["admin"],
                        isShow: true,
                    },
                    children: [
                      {
                            path: "orderList",
                            name: "OrderList",
                            icon: "Present",
                            component: "@/views/order/orderList.vue",
                            meta: {
                                title: "订单列表",
                                icon: "Tickets",
                                role: ["admin"],
                                isShow: true,
                            },

                        },
                        {
                          path: "refundOrderList",
                          name: "RefundOrderList",
                          icon: "Present",
                          component: "@/views/order/refundOrderList.vue",
                          meta: {
                              title: "订单退款列表",
                              icon: "Tickets",
                              role: ["admin"],
                              isShow: true,
                          },

                      },
                        {
                            path: "orderDetails",
                            name: "orderDetails",
                            icon: "Present",
                            component: "@/views/order/orderDetails.vue",
                            meta: {
                                title: "订单详情",
                                icon: "Tickets",
                                role: ["admin"],
                                isShow: false,
                            },


                        } 

                    ]
                },


                {
                  path: "/pack",
                  name: "Pack",
                  component: "",
                  icon: "Present",
                  meta: {
                      title: "礼包管理",
                      icon: "Present",
                      role: ["admin"],
                      isShow: true,
                  },
                  children: [{
                          path: "index",
                          name: "PackIndex",
                          icon: "Present",
                          component: "@/views/pack/index/index.vue",
                          meta: {
                              title: "礼包列表",
                              icon: "Tickets",
                              role: ["admin"],
                              isShow: true,
                              keepAlive: true,
                          },

                      }, 
                      {
                          path: "pack/item",
                          name: "pack_item",
                          icon: "Operation",
                          component: "@/views/pack/item/index.vue",
                          meta: {
                              title: "礼包内容管理",
                              icon: "Tickets",
                              role: ["admin"],
                              isShow: true,
                          },
                      },
                       
                      


                  ]

              },


                {
                    path: "/note",
                    name: "Note",
                    component: "",
                    icon: "Present",
                    meta: {
                        title: "笔记管理",
                        icon: "Present",
                        role: ["admin"],
                        isShow: true,
                    },
                    children: [{
                            path: "noteList",
                            name: "NoteList",
                            icon: "Present",
                            component: "@/views/note/noteList/index.vue",
                            meta: {
                                title: "笔记列表",
                                icon: "Tickets",
                                role: ["admin"],
                                isShow: true,
                                keepAlive: true,
                            },

                        },
                        {
                            path: "noteList/update",
                            name: "Update",
                            icon: "Present",
                            component: "@/views/note/noteList/update.vue",
                            meta: {
                                title: "编辑笔记",
                                icon: "Tickets",
                                role: ["admin"],
                                isShow: false,
                            },
                        },

                        {
                            path: "viewingRecord",
                            name: "NoteRecord",
                            icon: "Operation",
                            component: "@/views/note/log/index.vue",
                            meta: {
                                title: "笔记访问记录",
                                icon: "Tickets",
                                role: ["admin"],
                                isShow: true,
                            },
                        },


                    ]

                },


                //   科普管理
                {
                    path: "/science",
                    name: "science",
                    component: "",
                    icon: "Present",
                    meta: {
                        title: "科普管理",
                        icon: "Present",
                        role: ["admin"],
                        isShow: true,
                    },
                    children: [{
                            path: "scienceList",
                            name: "scienceList",
                            icon: "Present",
                            component: "@/views/science/scienceList/index.vue",
                            meta: {
                                title: "科普列表",
                                icon: "Tickets",
                                role: ["admin"],
                                isShow: true,
                                keepAlive: true,
                            },

                        },
                        {
                            path: "scienceList/update",
                            name: "scienceUpdate",
                            icon: "Present",
                            component: "@/views/science/scienceList/update.vue",
                            meta: {
                                title: "编辑科普",
                                icon: "Tickets",
                                role: ["admin"],
                                isShow: false,
                            },
                        },

                        {
                            path: "viewingRecord",
                            name: "scienceRecord",
                            icon: "Operation",
                            component: "@/views/science/log/index.vue",
                            meta: {
                                title: "科普访问记录",
                                icon: "Tickets",
                                role: ["admin"],
                                isShow: true,
                            },
                        },


                    ]

                },
 
                {
                  path: "/system",
                  name: "System",
                  component: "",
                  icon: "Present",
                  meta: {
                      title: "系统管理",
                      icon: "Present",
                      role: ["admin"],
                      isShow: true,
                  },
                  children: [{
                          path: "dict/data",
                          name: "Dict",
                          icon: "Present",
                          component: "@/views/system/dict/data/index.vue",
                          meta: {
                              title: "字典管理",
                              icon: "Tickets",
                              role: ["admin"],
                              isShow: true,
                              keepAlive: true,
                          },

                      },
                      {
                          path: "dict/data/edit",
                          name: "dict_edit",
                          icon: "Present",
                          component: "@/views/system/dict/data/edit.vue",
                          meta: {
                              title: "编辑字典",
                              icon: "Tickets",
                              role: ["admin"],
                              isShow: false,
                              keepAlive: true,
                          },
                      },
                      {
                          path: "dict/type",
                          name: "dictType",
                          icon: "Operation",
                          component: "@/views/system/dict/type/index.vue",
                          meta: {
                              title: "字典类型管理",
                              icon: "Tickets",
                              role: ["admin"],
                              isShow: true,
                          },
                      },
                      {
                        path: "dict/type/edit",
                        name: "DictTypeEdit",
                        icon: "Operation",
                        component: "@/views/system/dict/type/edit.vue",
                        meta: {
                            title: "编辑字典类型",
                            icon: "Tickets",
                            role: ["admin"],
                            isShow: false,
                            keepAlive: true,
                        },
                    },
                    {
                      path: "upgrade",
                      name: "Upgrade",
                      icon: "Present",
                      component: "@/views/system/upgrade/index.vue",
                      meta: {
                          title: "升级管理",
                          icon: "Tickets",
                          role: ["admin"],
                          isShow: true,
                          keepAlive: true,
                      },

                  },


                  ]

              },
             


            ];
            resovle({ code: 200, data: res });
        });
    }
}